.price-table {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
}

.price-table tr:not(.space) {
	border-bottom: 1px solid @brand-primary;
}

.price-table td {
	padding: 5px 0;
}

.price-table td:nth-child(2) {
	font-weight: bold;
	text-align: right;
}

.price-table td:nth-child(3) {
	color: green;
	text-align: right;
}

.top {
	position: relative;
	width:100%;
	background: @header;
	padding-top: 20px;
}
.divider {
	position: relative;
	width:100%;
	height:10px;
	background-color: @divider;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
           -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.divider:before,
.divider:after {
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.divider:after {
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}
.logo {
	position: relative;
	padding-bottom: 0px;
}
.logo-img {
	z-index: 99;
}
.logo-text {
	padding-top: 22px;
	position: absolute;
	top: 0;
	left: 15px;
}
.slogan h3 {
	color: @body-bg;
}
.main-content {
	margin-top: 15px;
}
h1 {
	margin-bottom:15px;
}	
.middle-content {
	width: 100%;
	margin: 40px 0;
	background: @brand-primary;

	h2, a {
		color: @headings-alt;
	}
}
.bottom-content {
	margin-bottom: 40px;
}
.button-clear {
	width: 150px;
	height: 30px;
	border: 1px solid #fff;
	padding-top: 5px;
	margin: 0 auto 20px auto;
	border-radius: 10px;
}
.bullet-bg {
	background-color: @brand-primary;
	border-radius: 10px;
	padding:10px;
	
	li {
		margin-left:30px;
	}
	}
.footer-bg {
	width: 100%;
	min-height: 100px;
	background: @footer;
	box-shadow: 0 0 10px 1px lighten(@footer, 15%);
	padding: 20px 0;
	margin-top: 40px;
	color: @footer-text;
}
.bottom-bar {
	width: 100%;
	height: 8px;
	background-color: @brand-primary;
}
.copyright {
	padding-top: 30px;
}
.lime {
	padding-top: 10px;
}
.top-img {
	padding-bottom: 20px;
}
.acceditation {
	img {
		padding-left: 20px;
		-webkit-filter: grayscale(100%);
		.transition(.5s);
	}
}
.acceditation {
	img:hover {
		-webkit-filter: grayscale(0%);
		.transition(.5s);
	}
}
.contact {
	text-align: center;
}

@media screen and (min-width: @screen-sm-min) {
	.contact {
		text-align: right;
	}
}

#area-map,
#area-map-small {
	margin-top: 30px;
}

.subtitle-pge {
	border-bottom: 1px solid #CBCBCB;
	border-top: 1px solid #CBCBCB;
	line-height: 1.4;
	margin-bottom: 25px;
	padding-bottom: 25px;
	margin-top: 25px;
	padding-top: 25px;
	font-weight: 200;
	text-align: center;
}

.get {
    display:none;
}


.contact-mobile a {
	width: 95%;
	display: inline-block;
	padding: 10px 20px;
	margin: 10px 2.5%;
	color: white;
	font-size: 20px;
	font-weight: 200;
	box-shadow: inset 0 0 10px -2px black;
	border-radius: 10px;
	span {
		float: right;
	}
	@mobile-grad:                darken(@brand-primary, 15%);
	background:         linear-gradient(@brand-primary, @mobile-grad);
	background:      -o-linear-gradient(@brand-primary, @mobile-grad);
	background:    -moz-linear-gradient(@brand-primary, @mobile-grad);
	background: -webkit-linear-gradient(@brand-primary, @mobile-grad);
	&:hover, &active, &:visited {
		text-decoration: none;
	}
}
